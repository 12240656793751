import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";

// components
import Header from "../components/header";
import Footer from "../components/footer";
import LoadingOverlay from "../components/loadingOverlay";

// layout
import LandingLayout from "../layout/landingLayout";
import MainLayout from "../layout/mainLayout";

// pages
import EmailSentInfo from "../pages/emailSentInfo";
import EnterEmail from "../pages/enterEmail";
import OneIdData from "../pages/oneIdData";
import DeleteSuccess from "../pages/deleteSuccess";

// thunk
import { getProfile } from "../thunks/auth";

const RootRouter = createBrowserRouter([
  {
    path: "/landing",
    Component: LandingLayout,
    children: [
      {
        path: "/landing/enterEmail",
        Component: EnterEmail,
      },
      {
        path: "/landing/emailSentInfo",
        Component: EmailSentInfo,
      },
    ],
  },
  {
    path: "/main",
    Component: MainLayout,
    children: [
      {
        path: "/main",
        Component: OneIdData,
      },
      {
        path: "/main/deleteSuccess",
        Component: DeleteSuccess,
      },
    ],
  },
  {
    path: "/",
    element: <Navigate replace to="/landing" />,
  },
]);

const RootContainer = (props) => {
  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state.authReducer);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // if (!reduxState?.oneIdData?.oid) {
    fetchProfile();
    // }
  }, []);

  const fetchProfile = async () => {
    try {
      let res = await dispatch(getProfile()).unwrap();
      setLoading(false);
      // console.log("Profile fetched", res);
    } catch (error) {
      setLoading(false);
      console.log("Error while fetching profile");
    }
  };

  if (loading) {
    return <LoadingOverlay />;
  }

  return (
    <div className="h-screen w-screen bg-gradient-to-b from-[#212427] to-[#353531] flex flex-col justify-between">
      <Header />
      <div className="overflow-auto no-scrollbar flex flex-col">
        <RouterProvider router={RootRouter}></RouterProvider>
        <Footer />
      </div>
    </div>
  );
};

export default RootContainer;
