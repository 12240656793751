import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const getInitialLanguage = () => {
  const storedLanguage = localStorage.getItem("i18nextLng");
  if (storedLanguage) {
    if (storedLanguage.includes("-")) {
      return storedLanguage.split("-")[0];
    } else {
      return storedLanguage;
    }
  } else {
    return "en";
  }
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // lng: getInitialLanguage(),
    debug: false,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    whitelist: ["en", "de", "fr", "it"],
    detection: {
      order: ["localStorage"],
    },
    resources: {
      en: {
        translation: {
          landing: {
            heading: "Privacy Centre",
            paragraph1:
              "The OneID Privacy Centre gives you access to all the data stored about you and an option to delete your OneID record.",
            paragraph2:
              "Simply enter your email address and we will send you a link to access your personal OneID Privacy Centre.",
            enterEmail: {
              heading: "Request access to your OneID",
              inputLabel: "Your email address",
              btnText: "Get access link",
              validationMsg1: "Please enter your email",
              validationMsg2: "Please enter a valid email address",
            },
            emailSentInfo: {
              heading: "Your access link is on its way",
              paragraph1:
                "We have sent you an email with a special link to access your OneID Privacy Centre. Please note that this link is only valid for 24 hours and can only be used once.",
              paragraph2:
                "It could take a few minutes for the email to arrive. And just in case, you might also want to check your spam folder.",
            },
            faq: {
              question1: "Why do we ask for your email address?",
              answer1:
                "We need your email address to identify the records associated with your OneID in our database. To ensure that the email address you provide belongs to you, we first send you an email with a link. This link takes you directly to your record, where you can view and, if desired, delete it.",
              question2:
                "What happens if I revoke my consent via a website's CMP?",
              answer2: {
                paragraph1:
                  "If you refuse the use of OneID via a publisher's TCF CMP that uses OneID, we place an opt-out cookie in your web browser indicating that you have chosen to opt out. Opting out means that OneID services will no longer be used on the participating website to enable targeted advertising for your browser, device, or email address. In other words, you will still see advertising, but it will be less relevant to you.",
                paragraph2:
                  "If you buy a new computer, update or change your web browser, or if this opt-out cookie is removed by deleting your browser files, you will be asked for consent again by the participating website.",
              },
              question3:
                "Your email address is not linked to OneID, but you want to know how to delete data collected by our partners or other companies?",
              answer3: {
                paragraph1:
                  "If you accept the use of OneID via the TCF CMP of a publisher, the OneID cookie is set on your computer or mobile device. If it is not linked to your email address, we have no way to centrally recognize you again. However, you have the option to delete the OneID cookie on your computer or mobile device by selecting the appropriate function in your browser.",
                list: {
                  title:
                    "You can learn about this option for the most commonly used browsers via the following links:",
                  item1: {
                    label: "Google Chrome",
                    link: "https://support.google.com/chrome/answer/95647?hl=en",
                  },
                  item2: {
                    label: "Microsoft Edge",
                    link: "https://support.microsoft.com/en-gb/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d",
                  },
                  item3: {
                    label: "Mozilla Firefox",
                    link: "https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox",
                  },
                  item4: {
                    label: "Apple Safari",
                    link: "https://support.apple.com/en-gb/guide/safari/sfri11471/mac",
                  },
                },
                paragraph2:
                  "You can find the OneID cookie with the search term “OneID.live”.",
              },
              question4: "Do you have any other questions?",
              answer4:
                "You can contact us at any time via <mailLink>oneid@onelog.ch</mailLink>.",
            },
          },
          main: {
            heading: "Welcome to your OneID Privacy Centre",
            paragraph1:
              "At OneID, we respect and protect your privacy. We aim to achieve a high level of data security, and we want to ensure that you are always in control of the kind of data we collect, process and share.",
            paragraph2:
              "Through your OneID Privacy Centre you can access all the data stored about you and associated to your email address, and you have the option to completely delete your OneID record.",
            userData: {
              heading: "Your OneID",
              downloadBtn: "Download your data",
              deleteBtn: "Delete your data",
              note: "Please note that we can only show or delete the data associated to your email address.",
              downloaded: "Data downloaded...",
            },
            confirmDelete: {
              heading:
                "Are you sure you want to delete your OneID and all related data? This cannot be undone.",
              buttonText: "Yes, delete all my data",
            },
            deleteSuccess: {
              heading:
                "Your OneID and all associated data have been successfully deleted",
            },
            question1: "What about data NOT associated to your email address?",
            answer1: {
              paragraph1:
                "Vaxë faina lillassëa tam en, rámië tanwëataquë sí tar. Laicë mittanya valarauko yén an. Mar úr ilma línë, coa mí halya lissë. Nixë yulda náhanemnam alu us, up ninwa mantil ruc. Tur engë haloitë ar, aiwë varnë amilessë lis at. Cua nú nixë tellaurë terpellië, et hón vírë rámië.",
              paragraph2:
                "Cu celë lívë vëaner tuv, fion laurëa tengwo ya óma, rondë atacar ai yat. Hap má caima lingwë. Cer ollo ninwa an. Huo us valinor etelotya, ná fëa sívë melissë. Ar píca costa úquétima lëo, ainu milya nortalarta low ep.",
            },
          },
          footer: {
            legal: "Legal info",
            privacy: "Data protection",
            cookies: "Cookie settings",
            copyright: "OneLog AG, Switzerland",
          },
        },
      },
      de: {
        translation: {
          landing: {
            heading: "Datenschutz-Center",
            paragraph1:
              "Das OneID Datenschutz-Center bietet Ihnen Zugriff auf alle über Sie gespeicherten Daten und die Möglichkeit, Ihren OneID-Datensatz zu löschen.",
            paragraph2:
              "Geben Sie einfach Ihre E-Mail-Adresse ein, und wir senden Ihnen einen Link, über den Sie auf Ihr persönliches OneID Datenschutz-Center zugreifen können.",
            enterEmail: {
              heading: "Zugang zu Ihrer OneID beantragen",
              inputLabel: "Ihre E-Mail Adresse",
              btnText: "Zugangslink anfordern",
              validationMsg1: "Bitte geben Sie Ihre E-Mail Adresse ein",
              validationMsg2: "Bitte geben Sie eine gültige E-Mail Adresse ein",
            },
            emailSentInfo: {
              heading: "Ihr Zugangslink ist auf dem Weg",
              paragraph1:
                "Wir haben Ihnen eine E-Mail mit einem speziellen Link für den Zugang zu Ihrem OneID Datenschutz-Center geschickt. Bitte beachten Sie, dass dieser Link nur 24 Stunden lang gültig ist und nur einmal verwendet werden kann.",
              paragraph2:
                "Es kann ein paar Minuten dauern, bis die E-Mail ankommt. Vorsichtshalber sollten Sie auch Ihren Spam-Ordner überprüfen.",
            },
            faq: {
              question1: "Warum fragen wir Sie nach Ihrer E-Mail-Adresse?",
              answer1:
                "Wir benötigen Ihre E-Mail-Adresse, um die zu Ihrer OneID gehörigen Datensätze in unserer Datenbank zu identifizieren. Um sicherzustellen, dass die von Ihnen angegebene E-Mail-Adresse auch Ihnen gehört, senden wir Ihnen in einem ersten Schritt eine E-Mail mit einem Link zu. Über den Link in der E-Mail gelangen Sie direkt zu Ihrem Datensatz und können diesen einsehen und falls gewünscht löschen.",
              question2:
                "Was passiert, wenn meine Einwilligung über die CMP einer Webseite widerrufe?",
              answer2: {
                paragraph1:
                  "Wenn Sie die Nutzung der OneID über die TCF CMP eines Publishers ablehnen, der die OneID nutzt, platzieren wir ein Opt-Out-Cookie in Ihrem Webbrowser, das anzeigt, dass Sie sich für die Ablehnung entschieden haben. Die Abmeldung bedeutet, dass die OneID-Dienste nicht mehr auf der teilnehmenden Webseite verwendet werden, um gezielte Werbung für Ihren Browser, Ihr Gerät oder Ihre E-Mail-Adresse zu ermöglichen. Mit anderen Worten: Sie werden weiterhin Werbung sehen, aber sie wird weniger relevant für Sie sein.",
                paragraph2:
                  "Wenn Sie einen neuen Computer kaufen, Ihren Webbrowser aktualisieren oder ändern oder dieses Opt-Out-Cookie durch Löschen Ihrer Browserdateien entfernt wird, werden Sie von der teilnehmenden Webseite erneut zur Einwilligung gefragt.",
              },
              question3:
                "Ihre E-Mail-Adresse ist nicht mit OneID verbunden, aber Sie möchten wissen, wie Sie die von unseren Partnern oder anderen Unternehmen gesammelten Daten löschen können?",
              answer3: {
                paragraph1:
                  "Wenn Sie die Nutzung der OneID über die TCF CMP eines Publishers akzeptieren, wird das OneID Cookie auf Ihrem Computer oder auf Ihrem mobilen Gerät gesetzt. Sofern dieses nicht mit Ihrer E-Mail-Adresse verbunden ist, haben wir keine Möglichkeit, Sie zentral wiederzuerkennen. Sie haben aber die Möglichkeit, das OneID Cookie auf Ihrem Computer oder auf Ihrem mobilen Gerät zu löschen, indem Sie die entsprechende Funktion in Ihrem Browser anwählen.",
                list: {
                  title:
                    "Über die folgenden Links können Sie sich über diese Möglichkeit für die am meisten verwendeten Browser informieren:",
                  item1: {
                    label: "Google Chrome",
                    link: "https://support.google.com/chrome/answer/95647?hl=DE",
                  },
                  item2: {
                    label: "Microsoft Edge",
                    link: "https://support.microsoft.com/de-de/windows/l%C3%B6schen-und-verwalten-von-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-11",
                  },
                  item3: {
                    label: "Mozilla Firefox",
                    link: "https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen",
                  },
                  item4: {
                    label: "Apple Safari",
                    link: "https://support.apple.com/de-ch/guide/safari/sfri11471/mac",
                  },
                },
                paragraph2:
                  "Das OneID Cookie können Sie mit dem Suchbegriff “OneID.live” finden.",
              },
              question4: "Haben Sie weitere Fragen?",
              answer4: `Gerne können Sie uns jederzeit über <mailLink>oneid@onelog.ch</mailLink> kontaktieren.`,
            },
          },
          main: {
            heading: "Willkommen in Ihrem OneID Datenschutz-Center",
            paragraph1:
              "Bei OneID respektieren und schützen wir Ihre Privatsphäre. Wir streben ein hohes Mass an Datensicherheit an und möchten sicherstellen, dass Sie immer die Kontrolle darüber haben, welche Daten wir sammeln, verarbeiten und weitergeben.",
            paragraph2:
              "Über Ihr OneID Datenschutz-Center können Sie auf alle über Sie gespeicherten und mit Ihrer E-Mail-Adresse verknüpften Daten zugreifen und Sie haben die Möglichkeit, Ihren OneID-Datensatz vollständig zu löschen.",
            userData: {
              heading: "Ihre OneID",
              downloadBtn: "Laden Sie Ihre Daten herunter",
              deleteBtn: "Löschen Sie Ihre Daten",
              note: "Bitte beachten Sie, dass wir nur die Daten anzeigen oder löschen können, die mit Ihrer E-Mail-Adresse verbunden sind.",
              downloaded: "Daten heruntergeladen...",
            },
            confirmDelete: {
              heading:
                "Sind Sie sicher, dass Sie Ihre OneID und alle damit verbundenen Daten löschen möchten? Dies kann nicht rückgängig gemacht werden.",
              buttonText: "Ja, alle meine Daten löschen",
            },
            deleteSuccess: {
              heading:
                "Ihre OneID und alle damit verbundenen Daten wurden erfolgreich gelöscht",
            },
            question1:
              "Was ist mit Daten, die NICHT mit Ihrer E-Mail-Adresse verknüpft sind?",
            answer1: {
              paragraph1:
                "Vaxë faina lillassëa tam en, rámië tanwëataquë sí tar. Laicë mittanya valarauko yén an. Mar úr ilma línë, coa mí halya lissë. Nixë yulda náhanemnam alu us, up ninwa mantil ruc. Tur engë haloitë ar, aiwë varnë amilessë lis at. Cua nú nixë tellaurë terpellië, et hón vírë rámië.",
              paragraph2:
                "Cu celë lívë vëaner tuv, fion laurëa tengwo ya óma, rondë atacar ai yat. Hap má caima lingwë. Cer ollo ninwa an. Huo us valinor etelotya, ná fëa sívë melissë. Ar píca costa úquétima lëo, ainu milya nortalarta low ep.",
            },
          },
          footer: {
            legal: "Impressum",
            privacy: "Datenschutz",
            cookies: "Cookie-Einstellungen",
            copyright: "OneLog AG, Schweiz",
          },
        },
      },
      fr: {
        translation: {
          landing: {
            heading: "Centre de protection de la vie privée",
            paragraph1:
              "Ici, vous avez accès à toutes les données stockées à votre sujet et vous avez la possibilité de supprimer votre dossier OneID.",
            paragraph2:
              "Il vous suffit de saisir votre adresse électronique et nous vous enverrons un lien pour accéder à votre Centre de protection de la vie privée OneID personnel.",
            enterEmail: {
              heading: "Demande d'accès à votre OneID",
              inputLabel: "Votre adresse électronique",
              btnText: "Obtenir le lien d'accès",
              validationMsg1: "Veuillez saisir votre adresse électronique",
              validationMsg2: "Veuillez saisir une adresse électronique valide",
            },
            emailSentInfo: {
              heading: "Votre lien d'accès est en route",
              paragraph1:
                "Nous vous avons envoyé un e-mail contenant un lien spécial pour accéder à votre Centre de protection de la vie privée OneID. Veuillez noter que ce lien n'est valable que pendant 24 heures et qu'il ne peut être utilisé qu'une seule fois.",
              paragraph2:
                "L'arrivée de l'e-mail peut prendre quelques minutes. Et juste au cas où, vous pouvez également vérifier votre dossier de spam.",
            },
            faq: {
              question1: "Pourquoi vous demandons-nous votre adresse e-mail?",
              answer1:
                "Nous avons besoin de votre adresse e-mail pour identifier les enregistrements associés à votre OneID dans notre base de données. Pour nous assurer que l'adresse e-mail que vous fournissez vous appartient, nous vous envoyons d'abord un e-mail avec un lien. Ce lien vous amène directement à votre enregistrement, où vous pouvez le consulter et, si vous le souhaitez, le supprimer.",
              question2:
                "Que se passe-t-il si je révoque mon consentement via le CMP d'un site web?",
              answer2: {
                paragraph1:
                  "Si vous refusez l'utilisation de OneID via le CMP TCF d'un éditeur qui utilise OneID, nous plaçons un cookie de désinscription dans votre navigateur web indiquant que vous avez choisi de vous désinscrire. La désinscription signifie que les services OneID ne seront plus utilisés sur le site web participant pour permettre la publicité ciblée pour votre navigateur, votre appareil ou votre adresse e-mail. En d'autres termes, vous verrez toujours de la publicité, mais elle sera moins pertinente pour vous.",
                paragraph2:
                  "Si vous achetez un nouvel ordinateur, mettez à jour ou changez votre navigateur web, ou si ce cookie de désinscription est supprimé en effaçant vos fichiers de navigateur, vous serez à nouveau invité à donner votre consentement par le site web participant.",
              },
              question3:
                "Votre adresse e-mail n'est pas liée à OneID, mais vous souhaitez savoir comment supprimer les données collectées par nos partenaires ou d'autres entreprises?",
              answer3: {
                paragraph1:
                  "Si vous acceptez l'utilisation de OneID via le CMP TCF d'un éditeur, le cookie OneID est défini sur votre ordinateur ou appareil mobile. S'il n'est pas lié à votre adresse e-mail, nous n'avons aucun moyen de vous reconnaître à nouveau de manière centrale. Cependant, vous avez la possibilité de supprimer le cookie OneID sur votre ordinateur ou appareil mobile en sélectionnant la fonction appropriée dans votre navigateur.",
                list: {
                  title:
                    "Vous pouvez vous informer sur cette option pour les navigateurs les plus utilisés via les liens suivants:",
                  item1: {
                    label: "Google Chrome",
                    link: "https://support.google.com/chrome/answer/95647?hl=fr&sjid=18095922185506160780-EU",
                  },
                  item2: {
                    label: "Microsoft Edge",
                    link: "https://support.microsoft.com/fr-fr/windows/supprimer-et-g%C3%A9rer-les-cookies-168dab11-0753-043d-7c16-ede5947fc64d",
                  },
                  item3: {
                    label: "Mozilla Firefox",
                    link: "https://support.mozilla.org/fr/kb/effacer-cookies-donnees-site-firefox",
                  },
                  item4: {
                    label: "Apple Safari",
                    link: "https://support.apple.com/fr-fr/guide/safari/sfri11471/mac",
                  },
                },
                paragraph2:
                  "Vous pouvez trouver le cookie OneID avec le terme de recherche “OneID.live”.",
              },
              question4: "Avez-vous d'autres questions ?",
              answer4:
                "Vous pouvez nous contacter à tout moment via <mailLink>oneid@onelog.ch</mailLink>.",
            },
          },
          main: {
            heading:
              "Bienvenue dans votre Centre de protection de la vie privée OneID",
            paragraph1:
              "Chez OneID, nous respectons et protégeons votre vie privée. Nous visons à atteindre un niveau élevé de sécurité des données et nous voulons nous assurer que vous contrôlez toujours le type de données que nous collectons, traitons et partageons.",
            paragraph2:
              "Par le biais de votre Centre de protection de la vie privée OneID, vous pouvez accéder à toutes les données stockées à votre sujet et associées à votre adresse électronique, et vous avez la possibilité de supprimer complètement votre dossier OneID.",
            userData: {
              heading: "Votre OneID",
              downloadBtn: "Téléchargez vos données",
              deleteBtn: "Effacez vos données",
              note: "Veuillez noter que nous ne pouvons afficher ou supprimer que les données associées à votre adresse électronique.",
              downloaded: "Données téléchargées...",
            },
            confirmDelete: {
              heading:
                "Êtes-vous sûr de vouloir supprimer votre OneID et toutes les données qui s'y rapportent? Cette opération ne peut être annulée.",
              buttonText: "Oui, effacez toutes mes données",
            },
            deleteSuccess: {
              heading:
                "Votre OneID et toutes les données qui y sont associées ont été supprimées avec succès",
            },
            question1:
              "Qu'en est-il des données NON associées à votre adresse électronique ?",
            answer1: {
              paragraph1:
                "Vaxë faina lillassëa tam en, rámië tanwëataquë sí tar. Laicë mittanya valarauko yén an. Mar úr ilma línë, coa mí halya lissë. Nixë yulda náhanemnam alu us, up ninwa mantil ruc. Tur engë haloitë ar, aiwë varnë amilessë lis at. Cua nú nixë tellaurë terpellië, et hón vírë rámië.",
              paragraph2:
                "Cu celë lívë vëaner tuv, fion laurëa tengwo ya óma, rondë atacar ai yat. Hap má caima lingwë. Cer ollo ninwa an. Huo us valinor etelotya, ná fëa sívë melissë. Ar píca costa úquétima lëo, ainu milya nortalarta low ep.",
            },
          },
          footer: {
            legal: "Mentions légales",
            privacy: "Protection des données",
            cookies: "Paramètres des cookies",
            copyright: "OneLog SA, Suisse",
          },
        },
      },
      it: {
        translation: {
          landing: {
            heading: "Centro Privacy",
            paragraph1:
              "Qui avete accesso a tutti i dati memorizzati su di voi e alla possibilità di cancellare il vostro record OneID.",
            paragraph2:
              "È sufficiente inserire il vostro indirizzo e-mail e vi invieremo un link per accedere al vostro Centro per la privacy OneID personale.",
            enterEmail: {
              heading: "Richiedete l'accesso alla vostra OneID",
              inputLabel: "Il vostro indirizzo e-mail",
              btnText: "Ottenere il link di accesso",
              validationMsg1: "Inserisci il tuo indirizzo e-mail",
              validationMsg2: "Inserire un indirizzo e-mail valido",
            },
            emailSentInfo: {
              heading: "Il link di accesso è in arrivo",
              paragraph1:
                "Vi abbiamo inviato un'e-mail con un link speciale per accedere al vostro Centro per la privacy OneID. Si prega di notare che questo link è valido solo per 24 ore e può essere utilizzato una sola volta.",
              paragraph2:
                "L'arrivo dell'e-mail potrebbe richiedere alcuni minuti. Per sicurezza, si consiglia di controllare la cartella spam.",
            },
            faq: {
              question1: "Perché chiediamo il vostro indirizzo email?",
              answer1:
                "Abbiamo bisogno del vostro indirizzo email per identificare i record associati al vostro OneID nel nostro database. Per assicurarci che l'indirizzo email che fornite sia vostro, vi inviamo prima una email con un link. Questo link vi porta direttamente al vostro record, dove potete visualizzarlo e, se lo desiderate, eliminarlo.",
              question2:
                "Cosa succede se revoco il mio consenso tramite il CMP di un sito web?",
              answer2: {
                paragraph1:
                  "Se rifiutate l'uso di OneID tramite il CMP TCF di un editore che utilizza OneID, inseriamo un cookie di opt-out nel vostro browser web che indica che avete scelto di non partecipare. Optare per l'uscita significa che i servizi OneID non verranno più utilizzati sul sito web partecipante per abilitare la pubblicità mirata per il vostro browser, dispositivo o indirizzo email. In altre parole, continuerete a vedere la pubblicità, ma sarà meno rilevante per voi.",
                paragraph2:
                  "Se acquistate un nuovo computer, aggiornate o cambiate il vostro browser web, o se questo cookie di opt-out viene rimosso cancellando i file del browser, vi verrà chiesto nuovamente il consenso dal sito web partecipante.",
              },
              question3:
                "Il vostro indirizzo email non è collegato a OneID, ma volete sapere come cancellare i dati raccolti dai nostri partner o altre aziende?",
              answer3: {
                paragraph1:
                  "Se accettate l'uso di OneID tramite il CMP TCF di un editore, il cookie OneID viene impostato sul vostro computer o dispositivo mobile. Se non è collegato al vostro indirizzo email, non abbiamo modo di riconoscervi nuovamente in modo centrale. Tuttavia, avete l'opzione di cancellare il cookie OneID sul vostro computer o dispositivo mobile selezionando la funzione appropriata nel vostro browser.",
                list: {
                  title:
                    "Potete informarvi su questa opzione per i browser più utilizzati tramite i seguenti link:",
                  item1: {
                    label: "Google Chrome",
                    link: "https://support.google.com/chrome/answer/95647?hl=it&sjid=18095922185506160780-EU",
                  },
                  item2: {
                    label: "Microsoft Edge",
                    link: "https://support.microsoft.com/it-it/windows/eliminare-e-gestire-i-cookie-168dab11-0753-043d-7c16-ede5947fc64d",
                  },
                  item3: {
                    label: "Mozilla Firefox",
                    link: "https://support.mozilla.org/it/kb/Eliminare%20i%20cookie",
                  },
                  item4: {
                    label: "Apple Safari",
                    link: "https://support.apple.com/it-it/guide/safari/sfri11471/mac",
                  },
                },
                paragraph2:
                  "Potete trovare il cookie OneID con il termine di ricerca “OneID.live”.",
              },
              question4: "Avete altre domande?",
              answer4:
                "Potete contattarci in qualsiasi momento tramite <mailLink>oneid@onelog.ch</mailLink>.",
            },
          },
          main: {
            heading: "Benvenuti nel Centro per la privacy OneID",
            paragraph1:
              "Noi di OneID rispettiamo e proteggiamo la vostra privacy. Puntiamo a raggiungere un elevato livello di sicurezza dei dati e vogliamo garantire che l'utente abbia sempre il controllo sul tipo di dati che raccogliamo, elaboriamo e condividiamo.",
            paragraph2:
              "Attraverso il Centro per la privacy OneID è possibile accedere a tutti i dati memorizzati su di voi e associati al vostro indirizzo e-mail, e avete la possibilità di eliminare completamente il vostro record OneID.",
            userData: {
              heading: "La vostra OneID",
              downloadBtn: "Scaricate i vostri dati",
              deleteBtn: "Cancellare i vostri dati",
              note: "Si noti che possiamo mostrare o cancellare solo i dati associati al vostro indirizzo e-mail.",
              downloaded: "Dati scaricati...",
            },
            confirmDelete: {
              heading:
                "Siete sicuri di voler eliminare la vostra OneID e tutti i dati correlati? Questa operazione non può essere annullata.",
              buttonText: "Sì, cancellare tutti i miei dati",
            },
            deleteSuccess: {
              heading:
                "La vostra OneID e tutti i dati associati sono stati eliminati con successo.",
            },
            question1: "E i dati NON associati al vostro indirizzo e-mail?",
            answer1: {
              paragraph1:
                "Vaxë faina lillassëa tam en, rámië tanwëataquë sí tar. Laicë mittanya valarauko yén an. Mar úr ilma línë, coa mí halya lissë. Nixë yulda náhanemnam alu us, up ninwa mantil ruc. Tur engë haloitë ar, aiwë varnë amilessë lis at. Cua nú nixë tellaurë terpellië, et hón vírë rámië.",
              paragraph2:
                "Cu celë lívë vëaner tuv, fion laurëa tengwo ya óma, rondë atacar ai yat. Hap má caima lingwë. Cer ollo ninwa an. Huo us valinor etelotya, ná fëa sívë melissë. Ar píca costa úquétima lëo, ainu milya nortalarta low ep.",
            },
          },
          footer: {
            legal: "Impronta",
            privacy: "Informativa sulla privacy",
            cookies: "Impostazioni dei cookie",
            copyright: "OneLog SA, Svizzera",
          },
        },
      },
    },
  });

export default i18n;
