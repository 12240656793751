import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import TaskIcon from "@mui/icons-material/Task";
import { useTranslation } from "react-i18next";
import { deleteData, downloadData } from "../thunks/auth";
import LoadingOverlay from "../components/loadingOverlay";
import { PiDownload } from "react-icons/pi";
import { MdOutlineDeleteForever } from "react-icons/md";
import JSZip from "jszip";

const OneIdData = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataDownloded, setDataDownloded] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const reduxState = useSelector((state) => state.authReducer);

  const handleDelete = async () => {
    try {
      setLoading(true);
      await dispatch(deleteData()).unwrap();
      navigate("/main/deleteSuccess");
    } catch (error) {
      alert("Error while deleting data");
      setLoading(false);
    }
  };

  const downloadDataHandler = async () => {
    try {
      setLoading(true);
      let data = await dispatch(downloadData()).unwrap();
      let zip = new JSZip();
      const currentDate = new Date().toISOString().split("T")[0];
      zip.file(`user_data_export_${currentDate}.json`, JSON.stringify(data));
      zip.generateAsync({ type: "blob" }).then(function (content) {
        const url = window.URL.createObjectURL(content);
        const a = document.createElement("a");
        a.href = url;
        a.download = `user_data_export_${currentDate}.zip`;
        a.click();
        window.URL.revokeObjectURL(url);
      });
      setDataDownloded(true);
      setLoading(false);
    } catch (error) {
      alert("Error while downloading data");
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <LoadingOverlay />}
      <div className="mt-[48px] sm:mt-[72px] lg:mt-[96px] py-[48px] sm:pt-[120px] sm:pb-[144px] flex justify-center items-center bg-[#A3BFA8] sm:rounded-xl shadow-[0_0px_36px_0px_rgba(0,0,0,0.5)]">
        <div className="w-[90%] sm:w-[65.8%] lg:w-[49%] flex flex-col text-[#212427] text-start">
          <h1 className="text-[18px] leading-[27px] font-bold font-Montserrat">
            {t("main.userData.heading")}
          </h1>
          <h2 className="mt-[12px] text-[16px] leading-[24px] font-bold italic">
            {reduxState.oneIdData?.oid}
          </h2>
          {/* <p className="text-[16px] leading-[24px]">
            {reduxState.oneIdData?.email}
          </p> */}
          {deleteConfirmation ? (
            <>
              <p className="mt-[36px] text-[16px] leading-[24px]">
                {t("main.confirmDelete.heading")}
              </p>
              <div
                className="mt-[24px] h-[48px] flex justify-center items-center rounded bg-[#EC4E20] hover:bg-[#D3370A] focus:border focus:border-[#353531] focus:ring-1 focus:ring-[#EC4E20]/60 target:border target:border-[#212427] target:bg-[#D3370A] focus-visible:outline-none cursor-pointer"
                tabIndex={0}
                onClick={handleDelete}
              >
                <p className="text-white text-[16px] font-bold">
                  {t("main.confirmDelete.buttonText")}
                </p>
              </div>
            </>
          ) : (
            <>
              {dataDownloded ? (
                <div
                  className="mt-[36px] h-[48px] flex justify-center items-center rounded bg-[#38B25D40]"
                  onClick={() => setDataDownloded(!dataDownloded)}
                >
                  <TaskIcon className="mr-[11.44px]" />
                  {t("main.userData.downloaded")}
                </div>
              ) : (
                <div
                  className="mt-[36px] h-[48px] flex justify-center items-center rounded border border-[#212427] bg-[#2124271a] hover:bg-[#D3370A] hover:border-[#D3370A] hover:text-white focus:border-[#D3370A] focus:ring-1 focus:ring-[#EC4E20]/60 focus-visible:outline-none cursor-pointer"
                  tabIndex={0}
                  onClick={() => downloadDataHandler()}
                >
                  <PiDownload size={21} className="mr-[11.44px]" />
                  {t("main.userData.downloadBtn")}
                </div>
              )}
              <div
                className="mt-[24px] h-[48px] flex justify-center items-center rounded border border-[#212427] bg-[#2124271a] hover:bg-[#D3370A] hover:border-[#D3370A] hover:text-white focus:border-[#D3370A] focus:ring-1 focus:ring-[#EC4E20]/60 focus-visible:outline-none cursor-pointer"
                tabIndex={0}
                onClick={() => setDeleteConfirmation(true)}
              >
                <MdOutlineDeleteForever size={21} className="mr-[11.44px]" />
                {t("main.userData.deleteBtn")}
              </div>
            </>
          )}
          <p className="mt-[36px] text-[16px] leading-[24px]">
            {t("main.userData.note")}
          </p>
        </div>
      </div>
    </>
  );
};

export default OneIdData;
