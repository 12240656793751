import "./App.css";
import "./custom.scss";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import RootContainer from "./containers/root";
import { Provider } from "react-redux";
import Store from "./store";

const languages = {
  de: {
    title: "OneID - die Ad ID für den Schweizer Markt",
    description:
      "OneID ist die Ad ID für den Schweizer Markt. Über 3 Millionen Nutzerinnen und Nutzer effizient und sicher erreichen. Jederzeit.",
  },
  fr: {
    title: "OneID - l'identifiant publicitaire pour le marché suisse",
    description:
      "OneID est l'identifiant publicitaire pour le marché suisse. Atteignez plus de 3 millions d'utilisateurs de manière efficace et sûre. A tout moment.",
  },
  it: {
    title: "OneID - l'Ad ID per la Svizzera",
    description:
      "OneID è l'ID pubblicitario per il mercato svizzero. Raggiungete oltre 3 milioni di utenti in modo efficiente e sicuro. In qualsiasi momento.",
  },
  en: {
    title: "OneID - the Ad ID for the Swiss market",
    description:
      "OneID is the Ad ID for the Swiss market. Reach over 3 million users efficiently and securely. At any time.",
  },
};

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    window.document.title = languages[i18n.language].title;
    window.document
      .querySelector('meta[name="description"]')
      .setAttribute("content", languages[i18n.language].description);
  }, [i18n.language]);

  return (
    <Provider store={Store}>
      <div className="App">
        <RootContainer />
      </div>
    </Provider>
  );
}

export default App;
