import { configureStore, compose } from "@reduxjs/toolkit";
import authReducer from "../slice/auth";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const Store = configureStore({
  reducer: {
    authReducer,
  },
  composeEnhancers: composeEnhancers(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export default Store;
