import React from "react";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <div className=" bg-[#212427] border-t border-solid border-[#EC4E20] flex justify-center py-6">
      <div className="w-full max-w-[940px] flex items-center flex-col gap-y-6 sm:gap-y-3 px-[20px] md:flex-row">
        <div className="flex flex-1 flex-col sm:flex-row gap-x-5 gap-y-3">
          <a
            href={`https://oneid.digital/${currentLanguage}/impressum/`}
            target="_blank"
            className="text-[#778DA9] text-sm hover:text-white hover:underline"
          >
            {t("footer.legal")}
          </a>
          <a
            href={`https://oneid.digital/${currentLanguage}/privacy-policy/`}
            target="_blank"
            className="text-[#778DA9] text-sm hover:text-white hover:underline"
          >
            {t("footer.privacy")}
          </a>
          <a
            href={`https://oneid.digital/${currentLanguage}/#`}
            target="_blank"
            className="text-[#778DA9] text-sm hover:text-white hover:underline"
          >
            {t("footer.cookies")}
          </a>
        </div>
        <span className="text-[#778DA9] text-sm text-center">
          © {t("footer.copyright")}
        </span>
      </div>
    </div>
  );
}

export default Footer;
