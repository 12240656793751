import axios from "axios";
import AppConfig from "../constant/appConfig";

export const getAxiosRequest = (customApiUrl) => {
  let authToken = localStorage.getItem("accessToken");
  let setCredentials = customApiUrl ? true : true;
  let contentType = customApiUrl ? "text/plain" : "application/json";
  let header = {
    "X-API-Key": AppConfig.api_key,
    Authorization: authToken ? `Bearer ${authToken}` : "",
  };
  var instance = axios.create({
    baseURL: customApiUrl || AppConfig.api_base_url,
    withCredentials: setCredentials,
    headers: customApiUrl ? { "content-type": contentType } : header,
  });
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        //window.location=`${AppConfig.loginUrl}`
        // document.location.reload();
      } else {
        return Promise.reject({ ...error });
      }
    }
  );

  return instance;
};

export const getHashValue = (val) =>
  crypto.subtle
    .digest("SHA-256", new TextEncoder("utf-8").encode(val))
    .then((h) => {
      let hexes = [],
        view = new DataView(h);
      for (let i = 0; i < view.byteLength; i += 4)
        hexes.push(("00000000" + view.getUint32(i).toString(16)).slice(-8));
      return hexes.join("");
    });
