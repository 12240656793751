import React from "react";
import { useTranslation } from "react-i18next";

const DeleteSuccess = () => {
  const { t } = useTranslation();

  return (
    <div className="mt-[48px] sm:mt-[72px] lg:mt-[96px] py-[48px] sm:pt-[120px] sm:pb-[144px] flex justify-center items-center bg-[#A3BFA8] sm:rounded-xl shadow-[0_0px_36px_0px_rgba(0,0,0,0.5)]">
      <div className="w-[90%] sm:w-[65.8%] lg:w-[49%] flex flex-col text-[#212427] text-start">
        <h1 className="text-[16px] leading-[24px] font-bold italic">
          {t("main.deleteSuccess.heading")}
        </h1>
      </div>
    </div>
  );
};

export default DeleteSuccess;
