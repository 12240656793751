import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxiosRequest, getHashValue } from "../helpers/index";

export const getProfile = createAsyncThunk(
  "auth/getProfile",
  async (model, thunkAPI) => {
    try {
      let res = await getAxiosRequest("https://api.oneid.live/api/v4").get(
        "/firstparty"
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const sendEmail = createAsyncThunk(
  "auth/sendEmail",
  async (model, thunkAPI) => {
    try {
      let res = await getAxiosRequest().post("/internal/send-email", model);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const login = createAsyncThunk("auth/login", async (model, thunkAPI) => {
  try {
    let res = await getAxiosRequest().post("/internal/swap-token", model);
    const matchOnId = await getHashValue(res.data.email);
    let attachHem = await getAxiosRequest("https://api.oneid.live/api/v4").post(
      "/firstparty",
      { e: { matchOnId: matchOnId } }
    );
    return {
      ...attachHem.data,
      accessToken: res.data.accessToken,
      email: res.data.email,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const deleteData = createAsyncThunk(
  "auth/deleteData",
  async (model, thunkAPI) => {
    try {
      let res = await getAxiosRequest().post(
        "/authenticateduser/delete-my-data",
        model
      );
      await getAxiosRequest("https://api.oneid.live/api/v4").post(
        "/firstparty",
        { e: { matchOnId: "delete" } }
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const downloadData = createAsyncThunk(
  "auth/downloadData",
  async (model, thunkAPI) => {
    try {
      let res = await getAxiosRequest().get("/authenticateduser/my-data");
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
