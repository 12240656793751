import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CircleIcon from "@mui/icons-material/Circle";
import { Add, Remove } from "@mui/icons-material";

const Accordion = styled((props) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square
    {...props}
    sx={{
      backgroundColor: `${props.expanded ? "#212427" : "transparent"}`,
      borderRadius: "4px",
      lineHeight: "24px",
      border: "1px solid transparent",
      ":focus-within": {
        border: "1px solid #EC4E20",
      },
    }}
  />
))(({ theme }) => ({
  color: "#ECF0F3",
  "&::before": {
    display: "none",
  },
  "& .MuiTypography-root": {
    fontFamily: "Open Sans, sans-serif",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={props.isopen === "true" ? <Remove /> : <Add />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "transparent",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },

  "& .MuiAccordionSummary-expandIconWrapper": {
    alignSelf: "flex-start",
    marginTop: "12px",
    color: "#ECF0F3",
    fontSize: "24px",
  },
  ":hover": {
    color: "#EC4E20",
    textDecorationLine: "underline",
    "& .MuiAccordionSummary-expandIconWrapper": {
      color: "#EC4E20",
    },
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingLeft: "48px",
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const FaqAccodions = () => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className="mt-[48px] sm:mt-[72px] lg:mt-[96px] mb-[60px] px-[5%] sm:px-0 flex flex-col gap-6">
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary isopen={(expanded === "panel1").toString()}>
          <Typography fontSize={16}>{t("landing.faq.question1")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography fontSize={16}>{t("landing.faq.answer1")}</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary isopen={(expanded === "panel2").toString()}>
          <Typography fontSize={16}>{t("landing.faq.question2")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography fontSize={16}>
            {t("landing.faq.answer2.paragraph1")}
          </Typography>
          <br />
          <Typography fontSize={16}>
            {t("landing.faq.answer2.paragraph2")}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary isopen={(expanded === "panel3").toString()}>
          <Typography fontSize={16}>{t("landing.faq.question3")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography fontSize={16}>
            {t("landing.faq.answer3.paragraph1")}
          </Typography>
          <br />
          <Typography fontSize={16}>
            {t("landing.faq.answer3.list.title")}
          </Typography>
          <List>
            {Array(4)
              .fill(0)
              .map((_, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemText className="text-[#778DA9] hover:text-white">
                    <a
                      href={t(`landing.faq.answer3.list.item${index + 1}.link`)}
                      target="_blank"
                      className="hover:underline"
                    >
                      <CircleIcon sx={{ fontSize: 10, marginRight: 2 }} />
                      {t(`landing.faq.answer3.list.item${index + 1}.label`)}
                    </a>
                  </ListItemText>
                </ListItem>
              ))}
          </List>
          <br />
          <Typography fontSize={16}>
            {t("landing.faq.answer3.paragraph2")}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary isopen={(expanded === "panel4").toString()}>
          <Typography fontSize={16}>{t("landing.faq.question4")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography fontSize={16}>
            <Trans
              i18nKey="landing.faq.answer4"
              components={{
                mailLink: (
                  <a
                    href="mailto: oneid@onelog.ch"
                    className="text-[#778DA9] hover:text-white hover:underline"
                  ></a>
                ),
              }}
            />
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default FaqAccodions;
