import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { sendEmail } from "../thunks/auth";
import LoadingOverlay from "../components/loadingOverlay";

const EnterEmail = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(0);
  const [loading, setLoading] = useState(false);

  const validationErrorMsgs = {
    1: t("landing.enterEmail.validationMsg1"),
    2: t("landing.enterEmail.validationMsg2"),
  };

  const validateEmail = (email) => {
    if (email === "") {
      setError(1);
      return false;
    }
    const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(email)) {
      setError(2);
      return false;
    }
    setError(0);
    return true;
  };

  const handleChange = (e) => {
    if (error) {
      validateEmail(e.target.value);
    }
    setEmail(e.target.value);
  };

  const onSubmit = async () => {
    try {
      if (validateEmail(email)) {
        setLoading(true);
        let reqModel = {
          email: email,
          locale: i18n.language.includes("-")
            ? i18n.language.split("-")[0]
            : i18n.language,
          matchOnId: "",
          matchOnIdEncrypted: false,
          emailEncrypted: false,
        };
        await dispatch(sendEmail(reqModel)).unwrap();
      }
    } catch (error) {
      alert("Error occurred while sending email");
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <LoadingOverlay />}
      <div className="mt-[48px] sm:mt-[72px] lg:mt-[96px] py-[48px] sm:pt-[120px] sm:pb-[144px] flex justify-center items-center bg-[#A3BFA8] sm:rounded-xl shadow-[0_0px_36px_0px_rgba(0,0,0,0.5)]">
        <div className="w-[90%] sm:w-[65.8%] lg:w-[49%] flex flex-col gap-12 text-[#212427] text-start">
          <h1 className="text-[18px] leading-[27px] font-bold font-Montserrat">
            {t("landing.enterEmail.heading")}
          </h1>
          <div className="flex flex-col gap-3">
            <p className="text-[14px] text-[#353531] font-semibold">
              {t("landing.enterEmail.inputLabel")}
            </p>
            <TextField
              id="email"
              variant="outlined"
              error={Boolean(error)}
              helperText={error ? validationErrorMsgs[error] : ""}
              value={email}
              onChange={handleChange}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#212427",
                  },
                  "&:hover fieldset": {
                    borderColor: "#212427",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#212427",
                  },
                  backgroundColor: "#FFFFFF40",
                },
                "& .MuiInputBase-input": {
                  fontFamily: "Open Sans, sans-serif",
                },
                "& .MuiFormHelperText-root.Mui-error": {
                  fontFamily: "Open Sans, sans-serif",
                  marginLeft: "0px",
                },
              }}
            />
          </div>
          <div
            className="h-[64px] flex justify-center items-center rounded bg-[#EC4E20] hover:bg-[#D3370A] focus:border focus:border-[#353531] focus:ring-2 focus:ring-[#EC4E20]/60 target:border target:border-[#212427] target:bg-[#D3370A] cursor-pointer"
            tabIndex={0}
            onClick={onSubmit}
          >
            <p className="text-white text-[16px] font-bold">
              {t("landing.enterEmail.btnText")}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnterEmail;
