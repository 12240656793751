import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import FaqAccodions from "../components/faqAccodions";

const LandingLayout = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const reducerState = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (reducerState.emailSent) {
      navigate("/landing/emailSentInfo");
    } else {
      navigate("/landing/enterEmail");
    }
  }, [reducerState.emailSent]);

  return (
    <div className="w-full max-w-[940px] text-start text-[#ECF0F3] bg-transparent self-center sm:px-[20px]">
      <h1 className="mt-[48px] sm:mt-[72px] lg:mt-[96px] px-[5%] sm:px-0 text-[34px] font-bold font-Montserrat leading-[46px]">
        {t("landing.heading")}
      </h1>
      <p className="mt-[48px] px-[5%] sm:px-0 text-[16px] leading-[24px]">
        {t("landing.paragraph1")}
      </p>
      <p className="px-[5%] sm:px-0 text-[16px] leading-[24px]">
        {t("landing.paragraph2")}
      </p>
      <Outlet />
      <FaqAccodions />
    </div>
  );
};

export default LandingLayout;
