import React from "react";
import { useTranslation } from "react-i18next";

const EmailSentInfo = () => {
  const { t } = useTranslation();
  return (
    <div className="mt-[48px] sm:mt-[72px] lg:mt-[96px] py-[48px] sm:pt-[120px] sm:pb-[144px] flex justify-center items-center bg-[#A3BFA8] sm:rounded-xl shadow-[0_0px_36px_0px_rgba(0,0,0,0.5)]">
      <div className="w-[90%] sm:w-[65.8%] lg:w-[49%] flex flex-col gap-3 text-[#212427] text-start">
        <h1 className="text-[18px] leading-[27px] font-bold font-Montserrat">
          {t("landing.emailSentInfo.heading")}
        </h1>
        <p className="text-[16px] leading-[24px]">
          {t("landing.emailSentInfo.paragraph1")}
        </p>
        <p className="text-[16px] leading-[24px]">
          {t("landing.emailSentInfo.paragraph2")}
        </p>

        {/* test button */}
        {/* <a
          className="text-center p-3 bg-[#EC4E20] text-white text-[16px] font-bold cursor-pointer rounded-xl"
          href="http://localhost:3000/main?token=123456789&&uid=123456789"
          target="_blank"
        >
          Access Privacy Centre
        </a> */}
      </div>
    </div>
  );
};

export default EmailSentInfo;
