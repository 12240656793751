/* eslint-disable no-unused-vars */
/**
 * App Config File
 */
// /*** localhost config
const AppConfigLocal = {
  app_title: "OneID Privacy Center local dev",
  app_id: "oneid-privacy-center local dev",
  api_base_url: "https://localhost:7120",
  api_key: "cfbdd523bf09f6bea9f1",
};

// /*** dev config
const AppConfigDev = {
  app_title: "OneID Privacy Center (dev)",
  app_id: "oneid-privacy-center",
  api_base_url: "https://s.identity.oneid.live",
  api_key: "lPnQmgvZLEiZBQS0SbiFPw",
};

// /*** production config
const AppConfigProd = {
  app_title: "OneID Privacy Center",
  app_id: "oneid-privacy-center",
  api_base_url: "https://identity.oneid.live",
  api_key: "4PnUmgvZPEiQBQS0HbfFKp",
};

// export default AppConfigLocal;
// export default AppConfigDev;
export default AppConfigProd;
