import React, { useEffect, useState } from "react";
import { useSearchParams, Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { login } from "../thunks/auth";
import LoadingOverlay from "../components/loadingOverlay";
import FaqAccodions from "../components/faqAccodions";

const MainLayout = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);

  const reduxState = useSelector((state) => state.authReducer);

  let token = searchParams.get("token");
  let uid = searchParams.get("uid");
  let language = searchParams.get("lng");

  useEffect(() => {
    if (language && language !== i18n.language) {
      i18n.changeLanguage(language);
    }
    if (!reduxState.oneIdData) {
      if (token && uid) {
        fetchData();
      } else {
        console.log("Missing Credentials, Please try again");
        navigate("/");
      }
    }
  }, [searchParams]);

  const fetchData = async () => {
    try {
      let model = {
        token: token,
        uid: uid,
      };
      await dispatch(login(model)).unwrap();
      setLoading(false);
    } catch (error) {
      console.log("login Failed, Please try again");
      navigate("/");
    }
  };

  if (loading) {
    return <LoadingOverlay />;
  }

  return (
    <div className="w-full max-w-[940px] text-start text-[#ECF0F3] bg-transparent self-center sm:px-[20px]">
      <h1 className="mt-[48px] sm:mt-[72px] lg:mt-[96px] px-[5%] sm:px-0 text-[34px] font-bold font-Montserrat leading-[46px]">
        {t("main.heading")}
      </h1>
      <p className="mt-[48px] px-[5%] sm:px-0 text-[16px] leading-[24px]">
        {t("main.paragraph1")}
      </p>
      <p className="mt-[10px] px-[5%] sm:px-0 text-[16px] leading-[24px]">
        {t("main.paragraph2")}
      </p>
      <Outlet />
      <FaqAccodions />
    </div>
  );
};

export default MainLayout;
