import { createSlice } from "@reduxjs/toolkit";
import {
  getProfile,
  sendEmail,
  login,
  deleteData,
  downloadData,
} from "../thunks/auth";

const initialState = {
  loading: false,
  emailSent: false,
  oneIdData: null,
};

const authSlice = createSlice({
  name: "authSlice",
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    // Get Profile
    builder.addCase(getProfile.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      // state.oneIdData = action.payload;
      state.loading = false;
    });
    builder.addCase(getProfile.rejected, (state, action) => {
      state.loading = false;
    });

    // Send Email
    builder.addCase(sendEmail.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(sendEmail.fulfilled, (state, action) => {
      state.emailSent = true;
      state.loading = false;
    });
    builder.addCase(sendEmail.rejected, (state, action) => {
      state.loading = false;
    });

    // Login
    builder.addCase(login.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.oneIdData = action.payload;
      localStorage.setItem("accessToken", action.payload.accessToken);
      localStorage.setItem("email", action.payload.email);
      localStorage.setItem("oid", action.payload.oid);
      state.loading = false;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
    });

    // Delete Data
    builder.addCase(deleteData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteData.fulfilled, (state, action) => {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("email");
      localStorage.removeItem("uid");
      state.loading = false;
    });
    builder.addCase(deleteData.rejected, (state, action) => {
      state.loading = false;
    });

    // Download Data
    builder.addCase(downloadData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(downloadData.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(downloadData.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default authSlice.reducer;
